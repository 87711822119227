import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import Prestamos from './prestamos';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

var drawerWidth = 350;


function RestaurarCancelarPrestamo(props) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[sucursal,setSucursal] = useState("")
  
  const[rows,setRows] = useState([])

  const drawerWidth = 240
  
  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 }]
  
    var rows1 = []    
    const columns = [
  { field: 'id', headerName: 'DOC #', width: 60 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    type: "string",
    width: 100,
    editable: true,
  },
  {
    field: 'nombres',
    headerName: 'Nombres',
    width: 150,
    editable: true,
  },
  {
    field: 'apellidos',
    headerName: 'Apellidos',
    type: 'string',
    width: 110,
    editable: true,
  },
  {
    field: 'nombrecompleto',
    headerName: 'Nombres-Apellidos',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    valueGetter: (params) =>
      `${params.row.nombres || ''} ${params.row.apellidos || ''}`,
  },
  {
    field: 'cantsol',
    headerName: 'Cantidad',
    type: 'string',
    width: 125,
  },
  {
    field: 'plazo',
    headerName: 'Plazo',
    type: 'string',
    width: 125,
  },

  {
    field: 'interes',
    headerName: 'Interes',
    type: 'string',
    width: 75,
  },

  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    width: 5,
  },
  {
    field: ' ',
    renderCell:(params)=>{
      return(
           <Button variant="contained" style={params.row.status === "A" ? {backgroundColor:"red", color:"white"} : {backgroundColor: "green",color:"white"}} 
              onClick={params.row.status === "A" ? ()=>cancelarprestamo(params.row.id,params.row.status): ()=>restaurarprestamo(params.row.id,params.row.status)}
              >{params.row.status === "A" ? <DeleteIcon/> : <RestoreIcon/>}</Button>    
      );
    }
  },
  
];

useEffect(() => {
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           setSucursal(data["sucursales"])  
          
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          setRows([]) 
          alert(data["Error"])
          
        })
    }})
},[]);

function cancelarprestamo(idfact,status) {
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'id': idfact,'password':localStorage.getItem("password"),"usuario":localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/cancelarprestamo',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           alert(data['mensajeok'])
           setRows([])         
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])        
        })
    }
  })

}

function restaurarprestamo(idfact,status) {
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'id': idfact,'password':localStorage.getItem("password"),"usuario":localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/restaurarprestamo',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           alert(data['mensajeok'])
           setRows([])         
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])        
        })
    }
  })

}


function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),"usuario":localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informesprestamos',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setRows(data["prestamo"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}


    return(
     <DashboardLayout>
     <Prestamos title="Cancelar/Restaurar Prestamos"/>  
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }}>
          <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs = {12} sm={2} md={2} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechadesde>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {2} md={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />                 
            </Grid>
            <Grid style={{marginLeft:"40px"}}  item xs = {12} sm = {2} md={2} >
                <Button style = {{color: "white",backgroundColor:"green"}} variant='contained' sx={{width:"150px",height:"41px",marginTop:"5px"}} onClick={()=>procesarrequerimiento()}>Procesar</Button>                 
            </Grid>
        </Grid>

        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>           
     </Box>
     </DashboardLayout>

    );    
}
export default RestaurarCancelarPrestamo

