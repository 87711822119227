import React, { useState,useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import {FormControl, InputLabel, MenuItem,Select,Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Administracion from './administracion';
import theme from "assets/theme";
import Iconpdf from 'assets/pdf/iconpdf';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import { WindowSharp } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {TextField} from '@mui/material';
import MDButton from 'components/MDButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Sidenav from "examples/Sidenav";
import { set } from 'firebase/database';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function InformeMotores() {
  var tcosto = 0.00
  var tvalor = 0.00

  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState({label:"0-TODO"})
  const[rowsdata,setRowsdata] = useState([])
  const[filteredRowdata,setFilteredRowdata] = useState([])
  const[filteredRowdataTable,setFilteredRowdataTable] = useState([])
  const[opendialog,setOpendialog] = useState(false)
  
  const [marcas,setMarcas] = useState([])
  const [modelos,setModelos] = useState([])
  const [filteredModelo,setFilteredModelo] = useState([])

  const[status,setStatus] = useState("0")
  const[sucursalnueva,setSucursalnueva] = useState("")
  const[sucursalactual1,setSucursalactual1] = useState("")
  const[chasis,setChasis] = useState("")  
  const[sucursal,setSucursal] = useState([])
  const [marca,setMarca] = useState("TODO")
  const [modelo,setModelo] = useState("TODO")
  const [valueInventory, setValueInventory] = useState(0)

  
  const [presentarpdf,setPresentarpdf] = useState(false)
  const theme1 = useTheme()
  const matches = useMediaQuery(theme1.breakpoints.down('sm'))

  const[controller] = useMaterialUIController()
  const {nombreNegocio,roleWeb} = controller 
  
  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'Chasis', width: 200 },
  {
    field: 'marca',
    headerName: 'Marca',
    width: 150,
    editable: true,
  }, {
    field: 'modelo',
    headerName: 'Modelos',
    type: 'string',
    width: 200,
    editable: true,
  },
  {
    field: 'colore',
    headerName: 'Color',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 130,
  },
  {
    field: 'cost2',
    headerName: 'Costo',
    type: 'string',
    width: 130,
  },
  {
    field: 'price2',
    headerName: 'Precio',
    type: 'string',
    width: 130,
  },
  {
    field: 'mprice2',
    headerName: 'Min precio',
    type: 'string',
    width: 130,
  },
  
  ];

  const columns1 = [
    { field: 'id', headerName: 'Chasis', width: 200 },
    {
      field: 'marca',
      headerName: 'Marca',
      width: 150,
      editable: true,
    }, {
      field: 'modelo',
      headerName: 'Modelos',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      field: 'colore',
      headerName: 'Color',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 130,
    },
    {
      field: 'cost2',
      headerName: 'Costo',
      type: 'string',
      width: 130,
    },
    {
      field: 'price2',
      headerName: 'Precio',
      type: 'string',
      width: 130,
    },
    {
      field: 'mprice2',
      headerName: 'Min precio',
      type: 'string',
      width: 130,
    },
    {
      field: 'nombres',
      headerName: 'Nombres',
      type: 'string',
      width: 150,
    },
    {
      field: 'balanceactual',
      headerName: 'Balance actual',
      type: 'string',
      width: 130,
    },
    {
      field: 'proyectado',
      headerName: 'Proyectado',
      type: 'string',
      width: 130,
    },

    
    ];
  


function clicktransferir(sucursalact,chasis){
   setChasis(chasis)
   setOpendialog(true)
   
}

useEffect(() => {
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
      'password':localStorage.getItem("password"),
      'usuario':localStorage.getItem('usuario')
      
    }    
    ) 
  }
  
  fetch(process.env.REACT_APP_SERVIDOR+'/api/mobile/inicialdata',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
          //setPorcobrar(data['porcobrar'])
          //setCobrados(data['cobrado'])
          //setClientescobrados(data['cantidaddecobrados'])
          setSucursal(data["sucursales"])  
          data["sucursales"].unshift({label:"0-TODO"})
          setRowsdata(data["productos"])
          setFilteredRowdataTable(data["productos"])
          setFilteredRowdata(data["productos"].map(item => item.chasis))


          var listmarcas = data['marcas'].map(item => item.name)
          listmarcas.unshift("TODO")
          setMarcas(listmarcas)
          
          var listmodelo = data['modelos']
          listmodelo.unshift("TODO")

          setModelos(listmodelo)



        })        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert("Error")
          
        })
    }}) 


},[]);

function procesarrequerimiento(event){
  var newList = rowsdata;

  if(nosucursal["label"]!="0-TODO"){
    newList = rowsdata.filter(item => item.sucursal==nosucursal["label"].split("-")[0])
    setFilteredRowdataTable(newList)
    setFilteredRowdata(newList.map(item => item.chasis))
  }
  else{
    setFilteredRowdataTable(rowsdata)
    setFilteredRowdata(rowsdata.map(item => item.chasis))
  }

  if(marca!="TODO"){        
      newList = newList.filter(item => item.marca==marca)        
      setFilteredRowdataTable(newList)  
      setFilteredRowdata(newList.map(item => item.chasis))

  }

  if(modelo!="TODO"){
      newList = newList.filter(item => item.modelo==modelo)
      setFilteredRowdataTable(newList)  
      setFilteredRowdata(newList.map(item => item.chasis))
  }

}

function getInventorybycategory(categoria){
  setStatus(categoria)
  setNosucursal({label:"0-TODO"})
  setMarca("TODO")
  setModelo("TODO")
  setFilteredRowdataTable([])
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'categoria': categoria,'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/mobile/getinventariobycategoria',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
            //rows = [data["facturacion"]]
             setRowsdata(data["productos"])
             setFilteredRowdataTable(data["productos"])
             setFilteredRowdata(data["productos"].map(item => item.chasis))
 

         
             
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRowsdata([]) 
            alert(data["Error"])
            
          })
      }
  })

}

function modificarsucursal() {
  //backend para modificar sucursal.
  let aerror = false
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'sucursalacambiar': sucursalnueva,'sucursalactual': sucursalactual1, 'chasis': chasis,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(estado.servidor+'/api/modificasucursal',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
           setOpendialog(false)
           alert(data["ok"])             
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])
          
        })
    }
  })
}

function alertar(cellValues){
  alert(cellValues)
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const MyDocument = () => (  
  <PDFViewer style={{height: "550px"}}>
  <Document>
    <Page size="A4" orientation='Portrait' wrap style={{paddingBottom: 65}}>
      <View style={{textAlign: "center", width: "94%", fontSize: 10,textTransform: "uppercase", marginTop:"15px" }} fixed> 
          <Text >{nombreNegocio}</Text>
      </View>
      
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text >Reporte de Motores</Text>
      </View>
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text>  </Text>
      </View>
          
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "gray", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", textAlign: "left"}} fixed>Fecha</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Marca</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Modelo</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Colore</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Chasis</Text>
          <Text style={{width: "115px", textAlign: "right"}} fixed>Costo</Text>
          <Text style={{width: "115px", textAlign: "right"}} fixed>Precio</Text>
          <Text style={{width: "100px", textAlign: "center"}} fixed>Status</Text>
          <Text style={{width: "100px", textAlign: "left"}} fixed>Condicion</Text>
        
      </View>
      {rowsdata.map((filas,i)  =>  
        <View key={i} style={{flexDirection: "row", width: "94%",height:"15px",fontSize: 10, color: "black",marginLeft:"15px"}} > 
          <Text style={{width: "100px", textAlign: "left"}} >{filas.fecha}</Text>
          <Text style={{width: "100px", textAlign: "left"}} >{filas.marca}</Text>
          <Text style={{width: "100px", textAlign: "left"}} >{filas.modelo}</Text>
          <Text style={{width: "100px", textAlign: "left"}} >{filas.colore}</Text>
          <Text style={{width: "100px", textAlign: "left"}} >{filas.chasis.substr(0,8)}</Text>
          <Text style={{width: "115px", textAlign: "right"}} >{filas.costo}</Text>
          <Text style={{width: "115px", textAlign: "right"}} >{filas.precio}</Text>
          <Text style={{width: "100px", textAlign: "center"}} >{filas.estado}</Text>
          <Text style={{width: "100px", textAlign: "left"}} >{filas.condicion}</Text>
                       
        </View>
      )}
      {rowsdata.forEach(function(totales){
        tcosto = tcosto + parseFloat(totales.costo.replace(",","").trim())
        tvalor = tvalor + parseFloat(totales.precio.replace(",","").trim())
  
      })}
      <Text> </Text>
      <View style={{display: "flex", flexDirection: "row", width: "94%",height:"35px",fontSize: 10, backgroundColor: "white", color: "black", marginLeft: "15px"}}> 
          <Text style={{width: "100px", textAlign: "left"}} >Totales</Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
          <Text style={{width: "115px", textAlign: "right"}}>{new Intl.NumberFormat('en-En').format(tcosto.toFixed(2))}</Text>
          <Text style={{width: "115px", textAlign: "right"}} >{new Intl.NumberFormat('en-EN').format(tvalor)} </Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
          <Text style={{width: "100px", textAlign: "left"}} > </Text>
         
      </View>
      
    </Page>
  </Document>
  </PDFViewer>
);


function handlepdf() {
  var MyDocument
  if (rowsdata.length == 0) {
     alert("No hay datos para procesar este reporte")
  } else {
    setPresentarpdf(true)       
  } 

}

function buscarChasis() {
  var list = rowsdata.filter(item=>item.chasis==chasis)
  setRowsdata(list)

}

return(
      <Box>

     <Box component="main" > 
        <Grid container direction="row" sx={{boxShadow:10}}  spacing={1} padding={3} style={{backgroundColor:"white"}}>
            <Grid item xs = {12} sm = {6} md={6} lg={2} >                                      
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={sucursal}
                  value={nosucursal["label"]}
                  renderInput={(params) => 
                    <TextField {...params}  
                        label="Sucursal" 
                        slotProps={{
                        input: {
                        ...params.InputProps,
                        type: 'search',
                        },
                    }}/>} 
                  onChange={(event,value)=>setNosucursal(value)}
                />                                                     
            </Grid>

            <Grid item xs = {12} sm = {6} md={6} lg={2}>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={marcas}
                  value={marca}
                  renderInput={(params) => 
                    <TextField {...params}  
                        label="Marcas" 
                        slotProps={{
                        input: {
                        ...params.InputProps,
                        type: 'search',
                        },
                    }}/>} 
                  onChange={(event,value)=>{
                    setMarca(value);
                    setModelo("");
                    var list = modelos.filter(item=>item.name==value);
                    var modelolist = list.map(item=>item.modelo)
                    modelolist.unshift("TODO")
                    setModelo("TODO")
                    setFilteredModelo(modelolist);
                  }}
                />                                                     
            </Grid>
            <Grid item xs = {12} sm = {6} md={6} lg={2}>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={filteredModelo}
                  renderInput={(params) => 
                    <TextField {...params}  
                        label="Modelos" 
                        slotProps={{
                        input: {
                        ...params.InputProps,
                        type: 'search',
                        },
                    }}/>} 
                    value={modelo}
                  onChange={(event,value)=>setModelo(value)}
                />                                                     
            </Grid>


            <Grid item xs = {12} sm = {6} md={6} lg={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select
                    style={{height:55}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value = {status}
                    onChange={(event) => getInventorybycategory(event.target.value)}>
                      <MenuItem value={"0"}>Todos</MenuItem>
                      <MenuItem value={"1"}>Incautados</MenuItem>
                      <MenuItem value={"2"}>Nuevo</MenuItem>
                      <MenuItem value={"3"}>Usado</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs = {12} sm = {12} md={12} >
               <Box display="flex">
                  <MDButton variant="gradient" color="success" style={{width:150}} onClick={()=>procesarrequerimiento()}>Procesar</MDButton> 
                  <MDButton variant="gradient" color="error" style={{width:150,marginLeft:10}} startIcon={<PictureAsPdfIcon/>} onClick={() => handlepdf()}>PDF</MDButton> 
                  {presentarpdf === true && (
                      <Dialog open={presentarpdf}
                       maxWidth = "lg"
                       fullWidth={true}
                       onClose={()=>setPresentarpdf(false)}
                       style = {{width:"100%", height:"500px"}}> 
                       <MyDocument/>     
                    </Dialog>)}
                </Box>
            </Grid>
            <Grid item xs = {12} sm = {12} md={6} marginTop={5}>
                <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={filteredRowdata}
                      renderInput={(params) => 
                        <TextField {...params}  
                            label="Chasis" 
                            slotProps={{
                            input: {
                            ...params.InputProps,
                            type: 'search',
                            },
                        }}/>} 
                        value={chasis}
                      onChange={(event,value)=>setChasis(value)}
                />                                              
                <MDButton variant="gradient" color="success" style={{width:150}} onClick={()=>buscarChasis()}>Buscar</MDButton>        
            </Grid>
        
            <Grid item xs = {12} sm = {12} md={12} marginTop={5}>
              
              <DataGrid
                style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
                sx={{boxShadow:5}}
                rows={filteredRowdataTable}
                columns={status === "1" ? columns1 : columns} 
                slots={{
                  toolbar: CustomToolbar,
                }}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                rowHeight={38}/>

            </Grid>
         
        
        
        </Grid>

        
     </Box>
     
     {/* Dialog Box*/}
     <Dialog
        open={opendialog}
        style = {{width:"100%", height:"500px"}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Digite por la sucursal a transferir?"}
        </DialogTitle>
        <DialogContent>
             <Sucursal
                onChange={(event)=>setSucursalnueva(event.target.value)}
                inputProps={{
                style: {
                  height: "5px",
                  width: "300px"
                    }
                }}
              />   
          <DialogActions>
                <Button variant="contained" style={{color: "white"}} onClick={()=>modificarsucursal()}>Aceptar</Button>
                <Button variant="contained" style={{color: "white"}} onClick={()=>setOpendialog(false)}>Cancelar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      
     </Box>

    );    
}

