/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import { FilterListRounded } from "@mui/icons-material";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import FilterDialog from "./filtro";

//import { db } from "./firebasedb";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, query, orderByChild  } from "firebase/database";
import data from "../Projects/data";



function OrdersOverview(props) {
  const [checkedModules, setCheckedModules] = useState([]);
  const [checkedAcciones, setCheckedAcciones] = useState([]);
  const [checkedsucursales, setCheckedsucursales] = useState([]);
  const [changeFilter, setChangeFilter] = useState(false);
  

  const firebaseConfig = {
    apiKey: "AIzaSyDTz01ErbwTUg7Kjz0ayhMKr8NXgzOOQJs",
    authDomain: "webmotors-e1b4a.firebaseapp.com",
    databaseURL: "https://webmotors-e1b4a-default-rtdb.firebaseio.com",
    projectId: "webmotors-e1b4a",
    storageBucket: "webmotors-e1b4a.appspot.com",
    messagingSenderId: "21788133575",
    appId: "1:21788133575:web:11592e57db8c3bd81396e2",
    measurementId: "G-5J6JYV3TJS"
  };
  
  
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  

  const [eventos,setEventos] = useState()
  const [openFilter,setOpenFilter] = useState(false)

  useEffect(() => {
    const dbRef = query(ref(database, localStorage.getItem("nombrenegocio").replaceAll(" ","")), orderByChild('hora'));
    
    onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setEventos(data)
      }
    });  
  }, []);

  
  function handleFilterChange(checkedAcciones,checkedModules,checkedsucursales){
    if(checkedAcciones.length==0 && checkedModules.length==0 && checkedsucursales.length==0){
      setChangeFilter(false)
    }
    else{
      setChangeFilter(true)
    }

  }


  return (
    <Card sx={{ height: "100%",width:270}} style={{maxWidth:270}}>
      
      <FilterDialog open={openFilter} setCheckedModules={setCheckedModules} setCheckedsucursales={setCheckedsucursales} setCheckedAcciones={setCheckedAcciones} setOpenFilter={setOpenFilter} handleFilterChange={handleFilterChange} sucursales={props.sucursales}></FilterDialog>
      <MDBox pt={3} px={3}>
        <MDBox display={"flex"} flexDirection={"row"}>
        <MDTypography variant="h6" fontWeight="medium">
          Eventos generales
        </MDTypography>

        <FilterListRounded style={{marginTop:2,marginLeft:20}} onClick={()=>{setOpenFilter(true)}}></FilterListRounded>
        </MDBox>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{marginTop:1 ,color: ({ palette: { info } }) => info.main }}>visibility</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              registros
            </MDTypography>{" "}
            de hoy
          </MDTypography>
        </MDBox>

      </MDBox>
      <MDBox p={2} style={{maxWidth:350}}>
        {eventos!=null?
          Object.values(eventos).reverse().map((data) => {
            var color = ''
            var icon = ''

            if(changeFilter){
              console.log(data)
              if(checkedAcciones.includes(data.Accion) && checkedModules.includes(data.Modulo) && checkedsucursales.includes(data.Sucursal)){
                switch(data.Accion){
                  case 'Registrar':
                    color='success'
                    icon='notifications'
                    break;
                  
                  case 'Cancelar':
                    color='warning'
                    icon='close'
                    break;
                  
                  case 'Eliminar':
                    color='error'
                    icon='delete'
                    break;
          
                }
    
    
                return(<TimelineItem
                color={color}
                icon={icon}
                title={data.Descripcion}
                dateTime={data.Fecha}
                sucursal={data.Sucursal}
              />)
  
              }

            }
            else{
              switch(data.Accion){
                case 'Registrar':
                  color='success'
                  icon='notifications'
                  break;
                
                case 'Cancelar':
                  color='warning'
                  icon='close'
                  break;
                
                case 'Eliminar':
                  color='error'
                  icon='delete'
                  break;
        
              }
  
  
              return(<TimelineItem
              color={color}
              icon={icon}
              title={data.Descripcion}
              dateTime={data.Fecha}
              sucursal={data.Sucursal}
            />)
  
              
            }
            
  
          })
        
        :''}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
