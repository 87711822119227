import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/inicio.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Person from "@mui/icons-material/Person";
import VpnKey from "@mui/icons-material/VpnKey";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Logo from "../../image/logo.ico";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useMaterialUIController,setNombrenegocio,setAccesoweb,setRoleweb } from "context";

function Login() {
    const [validacionlogin, setValidacionlogin] = useState("");
    const [validacionclave, setValidacionclave] = useState("");
    const [verpass, setVerpass] = useState(false);
    const [mensajeerror, setMensajeerror] = useState("");
    const [llamardasboard,setLlamardashboard] = useState(false)

    
    const [controller, dispatch] = useMaterialUIController();
    
    const navigate = useNavigate()
    let refEmail = React.createRef()
    
    window.history.pushState(null,null,"/")

    function handleLogin(event){
        let aerror = false
        if(aerror === false) {  
            if(validacionlogin.email === ""){
                refEmail.current.focus()
            } else { 
            }
        }

        const request = {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({usuario: validacionlogin, password: validacionclave}) 
        }
        fetch(process.env.REACT_APP_SERVIDOR+'/api/loginbackend_buscardatosiniciales',request)
        .then(function(response){
            if (response.status === 200) {
                response.json().then(function(data){
                    setValidacionclave("")
                    setValidacionlogin("")
                    setNombrenegocio(dispatch,data['nombrenegocio'][0]['ncomp'])
                    setAccesoweb(dispatch,data['usuarios'][0]['web'])
                    setRoleweb(dispatch,data['usuarios'][0]['tipo'])
                    localStorage.setItem("id",data["id"])
                    localStorage.setItem("usuario",validacionlogin)
                    localStorage.setItem("password",validacionclave)
                    localStorage.setItem("roleweb",data['usuarios'][0]['tipo'])
                    localStorage.setItem("sucursal",data['usuarios'][0]['sucursal'])
                    localStorage.setItem("nombrenegocio",data['nombrenegocio'][0]['ncomp'])
                    if (data['usuarios'][0]['web'] === "S" || data['usuarios'][0]['web'] === "s") {
                      navigate('/dashboard')
                    } else {
                      alert("Este usuario no tiene acceso al sistema web")
                    }
                })
                
            }
            if (response.status === 400) {
                response.json().then(function(data){
                   alert('** Error:'+data['Error'])
                })
            }
        })
       
    
    }

    return(
        <div>
      <BasicLayout image={bgImage}>
                
       <Grid container
            alignItems="center"
            direction="column"
        >
        <Grid item xs={12} sm={12} md={12} lg={12}>  
         <Card style={{width: "100%", height: "400px"}}>
              <CardContent align="center">
                  <img style={{height:"50px"}} src={Logo} alt="logo"></img>
                  <br></br>
                  <Typography variant="h6">Ingresa a tu cuenta</Typography>
                  
              </CardContent>

              <CardContent>
                  
                  <TextField 
                      variant = "outlined"
                      label = "Usuario"
                      fullWidth= {true}
                      onChange={event=>setValidacionlogin(event.target.value)}
                      value = {validacionlogin}
                      inputRef = {refEmail}
                      type = "text"
                      id = "outlined-adornment-password"
                      
                      InputLabelProps={{shrink: true}}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <Person/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      
                      
                      >
                          
                  </TextField>
                  
                  <br></br><br></br>
                  
                  <TextField 
                      variant = "outlined"
                      label = "Clave"
                      fullWidth= {true}
                      onChange={event=>setValidacionclave(event.target.value)}
                      value = {validacionclave}
                      id = "standard"
                      type = {verpass ? "text": "password"}
                      InputLabelProps={{shrink: true}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setVerpass(!verpass)}>
                                <VpnKey/>
                            </IconButton>
                            
                          </InputAdornment>
                        ),
                      }}
                      
                      
                      >
                          
                  </TextField>
                  
                  <br></br><br></br>
                  <Button style={{color: "white"}} onClick={handleLogin} variant="contained">Ingresar</Button>
                  <Typography style={{fontSize:"12px", color:"red", marginTop:"10px"}}>{mensajeerror}</Typography>

              </CardContent>

         </Card>
        </Grid> 
        </Grid>
        </BasicLayout>         

        </div>        
        

        )
}
export default Login; 