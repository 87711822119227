
import { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Autocomplete, TextField } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MDButton from "components/MDButton";


export default function FilterDialog(props) {
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');

  const [acciones,setAciones] = useState(["Cancelar", "Registrar", "Eliminar"])
  const [modulos,setModulos] = useState(["Inventario","Facturacion","Incautos","Prestamos","Pagos","Cargos","Cuentas Pagar","Matricula","Incobrables","Gastos"])

  const [checkedModules, setCheckedModules] = useState([]);
  const [checkedAcciones, setCheckedAcciones] = useState([]);
  const [checkedsucursales, setCheckedsucursales] = useState([]);

  // Handle checkbox change
  const handleChangeCheckedModules = (event) => {
    const value = event.target.name;
    if (event.target.checked) {
        setCheckedModules([...checkedModules, value]);
        props.setCheckedModules([...checkedModules, value]);
    } else {
        setCheckedModules(checkedModules.filter(item => item !== value));
        props.setCheckedModules(checkedModules.filter(item => item !== value));
    }    
    props.handleFilterChange(checkedAcciones,checkedModules,checkedsucursales)
  };

  const handleChangeCheckedAcciones = (event) => {
    const value = event.target.name;
    if (event.target.checked) {
        setCheckedAcciones([...checkedAcciones, value]);
        props.setCheckedAcciones([...checkedAcciones, value]);
    } else {
        setCheckedAcciones(checkedAcciones.filter(item => item !== value));
        props.setCheckedAcciones(checkedAcciones.filter(item => item !== value));
    }    
    props.handleFilterChange(checkedAcciones,checkedModules,checkedsucursales)
  };

  const handleChangeCheckedsucursales = (event) => {
    const value = event.target.name;
    if (event.target.checked) {
        setCheckedsucursales([...checkedsucursales, value]);
        props.setCheckedsucursales([...checkedsucursales, value]);
    } else {
        setCheckedsucursales(checkedsucursales.filter(item => item !== value));
        props.setCheckedsucursales(checkedsucursales.filter(item => item !== value));
    }    
    props.handleFilterChange(checkedAcciones,checkedModules,checkedsucursales)
  };

  const handleClose = () => {
    props.setOpenFilter(false)
  };

  const handleClear = () => {
    
    setCheckedAcciones([])
    setCheckedModules([])
    setCheckedsucursales([])

    props.setCheckedAcciones([])
    props.setCheckedModules([])
    props.setCheckedsucursales([])
    props.handleFilterChange([],[],[])


  };


  return (
    <Box>
      {console.log(checkedModules)}
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>Filtros</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Filtra tus eventos como prefieras
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
           <Accordion style={{marginTop:20}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                  <h5>Acciones</h5>              
              </AccordionSummary>


            <FormControl sx={{ mt: 2, minWidth: 220 ,marginLeft:10}}>
              <FormGroup>
                {acciones.map((name) => (
                  <FormControlLabel
                    key={name}
                    control={
                    <Checkbox
                      checked={checkedAcciones.includes(name)}
                      onChange={handleChangeCheckedAcciones}
                      name={name}/>
                    }
                  label={name}
                  />
                ))}
              </FormGroup>
            </FormControl>

            </Accordion>
            <Accordion style={{marginTop:20}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                  <h5>Modulos</h5>              
              </AccordionSummary>


            <FormControl sx={{ mt: 2, minWidth: 220 ,marginLeft:10}}>
              <FormGroup>
                {modulos.map((name) => (
                  <FormControlLabel
                    key={name}
                    control={
                    <Checkbox
                      checked={checkedModules.includes(name)}
                      onChange={handleChangeCheckedModules}
                      name={name}/>
                    }
                  label={name}
                  />
                ))}
              </FormGroup>
            </FormControl>

            </Accordion>

            <Accordion style={{marginTop:20}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                  <h5>Sucursales</h5>              
              </AccordionSummary>


            <FormControl sx={{ mt: 2, minWidth: 220 ,marginLeft:10}}>
              <FormGroup>
                {props.sucursales.map((name) => (
                  <FormControlLabel
                    key={name.label}
                    control={
                    <Checkbox
                      checked={checkedsucursales.includes(name.label)}
                      onChange={handleChangeCheckedsucursales}
                      name={name.label}/>
                    }
                  label={name.label}
                  />
                ))}
              </FormGroup>
            </FormControl>

            </Accordion>


          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" color="error" onClick={handleClear}>Limpiar filtro</MDButton>
          <MDButton variant="gradient" color="success" onClick={handleClose}>Listo</MDButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}