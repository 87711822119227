import { useState,useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import { Padding, TwoWheelerRounded } from "@mui/icons-material";


function Transferencias(props) {
  
  const [rows,setRows]  = useState([])

  const columns = [
    { Header: "Transferencia", accessor: "transferencia", width: "35%", align: "left" },
    { Header: "Fecha salida", accessor: "Fechasalida", width: "15%", align: "left" },
    { Header: "Hora salida", accessor: "Horasalida", align: "center" },
    { Header: "status", accessor: "status", align: "center" },
  ]

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox style={{backgroundColor:"black",padding:4,borderRadius:4}}>
        <TwoWheelerRounded style={{color:"white"}}></TwoWheelerRounded>
      </MDBox>
      
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  useEffect(() => {
    var result = props.transferencia.map((value)=>(
      
        {
          transferencia: (<Company  name={value["stransmisor"].split("-")[1]+" - "+value["sreceptor"].split("-")[1]} />),
          Fechasalida: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {value["fechatransm"].split(" ")[0]}
            </MDTypography>
          ),
          Horasalida: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {value["fechatransm"].split(" ")[1]}
            </MDTypography>
          ),
          status: (
            <MDBox width="8rem" textAlign="left">
              <MDProgress value={50} color="info" variant="gradient" label={false} />
            </MDBox>
          ),
        }
      
      )
    )

    setRows(result)
  

  }, []);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Transferencias en curso
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{rows.length} en progreso</strong> hoy
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Transferencias;
