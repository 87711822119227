import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
// @mui material components
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import Draw from '@mui/material/Drawer';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Alert from '@mui/material/Alert';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator,setOpenSide, } from "context";
// Material Dashboard 2 React routes
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes";
import Sidenav from "examples/Sidenav";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import Icon from "@mui/material/Icon";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import CssBaseline from "@mui/material/CssBaseline";
import { Drawer } from "@mui/material";
import List from "@mui/material/List";
import OrdersOverview from "../components/OrdersOverview";
import Projects from "../components/Projects";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import PieChart from "examples/Charts/PieChart";
import PolarChart from "examples/Charts/PolarChart";
import RadarChart from "examples/Charts/RadarChart";
import BubbleChart from "examples/Charts/BubbleChart";
import { BarChart } from "@mui/icons-material";
import Transferencias from "../components/Transferencias";
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MixedChart from "examples/Charts/MixedChart";


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function DashboardPantalla() {
  const [sales, setSales] = useState("");
  const [task, setTask] = useState("");
  const [facturacionmes,setFacturacionmes] = useState(0)
  const [cantidadmotores,setCantidadmotores] = useState(0)
  const [financiado,setFinanciado] = useState(0)
  const [pagos,setPagos] = useState(0)
  const [incautos,setIncautos] = useState(0)
  const [gastos,setGastos] = useState(0)
  const [barchart,setBarchart] = useState("")
  const [ventas,setVentas] = useState("")
  const [sucursales,setsucursales] = useState([])
  const [onMouseEnter,setOnMouseEnter] = useState(false)
  const [currentsucursal,setCurrentSucursal] = useState([])
  const [transferencias,setTransferencias] = useState([])

  const [controller, dispatch] = useMaterialUIController();
  const {
    openSide,
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
  
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setOpenSide(dispatch, true);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  

  const drawerOpenStyles = () => ({
    width: 270,
    right: "initial",
    left: 0,
  });

  
  
  useEffect(() => {

    //recuperar el total de facturacion del mes
    buscardata(" ")
  }, []);

  function buscardata(s){
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({"sucursal":s,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/facturacionultimomes',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]        
             setFacturacionmes(data['facturacion'][0]['precio'])
             setCantidadmotores(data['facturacion'][0]['cantidad'])
             setFinanciado(data['prestamo'][0]['valor'])
             setPagos(data['pagos'][0]['valor'])
             setIncautos(data['incautos'][0]['valor'])
             setGastos(data['gastos'][0]['valor'])
             setsucursales(data["sucursales"])
             setTransferencias(data["transferencias"])
                           
             //crear objeto barchart
             var barchar = new Object()
             barchar.labels = data['datachart']['label']
             barchar.datasets = {label: "Ventas", data: data['datachart']['valor']}
             setBarchart(barchar)
      
             var mesvalor = new Object()
             mesvalor.labels = data['mesvalor']['label']
             mesvalor.datasets = {label: "Motores Facturados",data: data['mesvalor']['valor']}
             setSales(mesvalor)

             var pagosmesvalor = new Object()
             pagosmesvalor.labels = data['pagosvalor']['label']
             pagosmesvalor.datasets = {label: "Pagos Mensuales", data: data['pagosvalor']['valor']} 
             setVentas(pagosmesvalor)




          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            alert(data["Error"])
            
          })
      }
  })  

  }

  const[time,setTime] = useState(Date.now())

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact  path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const [state, setState] = useState({
      open: false,
      Transition: Slide,
    });
  
    const handleClick = (Transition) => () => {
      setState({
        open: true,
        Transition,
      });
    };
  
    const handleClose = () => {
      setState({
        ...state,
        open: false,
      });
    };
  

  
  return (
    <DashboardLayout>
      
{/*      <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          variant="filled" severity="warning"
          sx={{ width: '100%' }}
          style={{color:"white"}}
        >
        This is a success Alert inside a Snackbar!
        </Alert>
      </Snackbar>
*/}      
        <ThemeProvider theme={darkMode ? themeDark : theme}>
        <Grid container marginTop={2}>
          <Grid item lg={8}>
            <h2>Dashboard</h2>
            <h2>{localStorage.getItem("nombrenegocio")} </h2>
            <br></br>
          </Grid>
          <Grid item lg={3}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sucursales}
              value = {currentsucursal}
              onBlur={(event) => setCurrentSucursal(event.target.value)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="sucursales" />}
            />
            <Box display="flex" flexDirection={"row-reverse"}>
               <MDButton variant="gradient" color="success" onClick={()=>{buscardata(currentsucursal.split("-")[0])}}>Buscar</MDButton>
            </Box>
            
          </Grid>

        </Grid>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
          <Draw
            sx={{
            '& .MuiDrawer-paper': {
            height: "100vh",
            margin: 0,
            padding: `1opx`,
            borderRadius: 0,
            boxShadow: 5,
            overflowY: "auto",
            ...(drawerOpenStyles())},
            }}
            variant = 'persistent'
            open = {true}>

              <Grid container>
                <Grid item lg={12}>
                  <OrdersOverview sucursales={sucursales}></OrdersOverview>
                </Grid>

              </Grid>

          </Draw>

  
        </>
      )}
      
    </ThemeProvider>
      <MDBox py={3}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={12} >
          {transferencias.length>0?
            <Transferencias transferencia={transferencias}></Transferencias>
          :''
          }
          
        </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Facturacion/Mes"
                count={facturacionmes.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Facturacion Ult. Mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Cantidad Motores"
                count={cantidadmotores}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Financiado"
                count={financiado.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Cobros Realizados"
                count={pagos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Incautos"
                count={incautos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Gastos"
                count={gastos.toLocaleString("en-US", {style:"currency", currency:"USD"})}
                percentage={{
                  color: "success",
                  label: "Ultimo mes",
                }}
              />
            </MDBox>
          </Grid>
 
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Cobros Ultima Semana"
                  description="Lunes a Viernes"
                  date= {new Date().toDateString()}
                  chart={barchart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Ventas Motores"
                  description="ultimo 6 meses"
                  date={new Date().toDateString()}
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Cobros Realizados"
                  description="Cobros ultimos 6 meses"
                  date={new Date().toDateString()}
                  chart={ventas}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <DefaultDoughnutChart
                  color="dark"
                  title="Cobros Realizados"
                  description="Cobros ultimos 6 meses"
                  date={new Date().toDateString()}
                  chart={ventas}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <RadarChart
                  color="dark"
                  title="Cobros Realizados"
                  description="Cobros ultimos 6 meses"
                  date={new Date().toDateString()}
                  chart={[]}
                />
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
    
}

export default DashboardPantalla;
