import React, { useState, useContext, useEffect,useMemo } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import MDButton from 'components/MDButton';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import BootstrapInput from 'components/MDField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Check, Close, KeyboardReturnRounded, LocalShippingRounded, MoreVertOutlined } from '@mui/icons-material';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



var drawerWidth = 350;



function Listtransf(props) {
  
   const [currentItem,setCurrentItem] = useState()
   const [currentsucursal,setCurrentSucursal] = useState('')
   const [rows,setRows] = useState([])
   const [transferidos,setTransferidos] = useState([])
   const sucursales = props.sucursales;

   const [expanded, setExpanded] = useState(0);
   const [filtered, setfiltered] = useState([])
   const [completos, setCompletos] = useState([])
   const [progreso, setProgreso] = useState([])
   
   

  function buscarmotores(){

    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'tipo':props.tipo,'sucursal': currentsucursal,'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/listtransf',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
            //rows = [data["facturacion"]]
             setRows(data["motorgroup"])
             setTransferidos(data["transacciones"])
             setCurrentItem(undefined)
             
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
    })
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  
  function filteredTranf(idtransaccion){
    var item = transferidos.filter(value => value['idtransaccion']===idtransaccion);    
    return item;
  };

  function iscompleted(idtransaccion){
    var completed = filteredTranf(idtransaccion).filter(value=> value['status']==="Devuelta" || value['status']==="Completado")
    var iscompleted = false;
    if(filteredTranf(idtransaccion).length == completed.length){
      iscompleted = true;
    }

    return iscompleted;
  };

  function devoluciones(idtransaccion){
    var devueltas = filteredTranf(idtransaccion).filter(value=> value['status']==="Devuelta")
    
    return devueltas;
  };


    return (
      <Grid container spacing={2}>
        <Grid item lg={8} marginTop={5}>
            <Grid container>
                <Grid item lg={5}>
                    <h3>Transferencias...</h3>
                    <Autocomplete
                        style={{marginTop:"0px",backgroundColor:"white",width:"300px"}}
                        variant = "standard"    
                        disablePortal
                        id="combo-box-demo"
                        options={sucursales}
                        value = {[]}
                        onBlur={(event) => setCurrentSucursal(event.target.value)}    
                        sx = {{ disableUnderline: true}}
                                      
                        renderInput={(params) => 
                          <TextField {...params} 
                            variant = "standard"
                            label="seleccionar sucursal" 
                            sx={{boxShadow: 4, borderRadius: 2,width:"300px" ,height:"55px",backgroundColor: "white"}}
                            InputLabelProps={{
                                shrink: "true",
                                style:{
                                    paddingLeft: "10px",
                                    paddingTop: "5px",
                                    disableUnderline: true,
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,disableUnderline:true,borderRadius:5,
                                style:{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                    marginTop: "20px",  
                            }}}
                            />}
                    />
                </Grid>
                <Grid item lg={2} marginTop={6} marginLeft={1}>
                  <MDButton variant="gradient" color="success" onClick={()=>buscarmotores()}  fullWidth>Buscar</MDButton>
                </Grid>

                <Grid item lg={12} sx={{backgroundColor:'white',borderRadius:2,boxShadow:5,minHeight:200}} style={{backgroundColor:'white'}} marginTop={4}>
                    {rows.map((value,i)=>(
                        <Accordion expanded={expanded === i} onChange={handleChange(i)}>
                          
                            <AccordionSummary
                                onClick={()=>{setCurrentItem(value)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{fontWeight:"bold"}}>{`${value["stransmisor"].split("-")[1]}-${value["sreceptor"].split("-")[1]} ${value['fechatransm']}`} 
                                
                                {iscompleted(value["idtransaccion"])?
                                  <p style={{marginTop:3,marginLeft:10,fontWeight:"bold",fontSize:14,color:'green',backgroundColor:"#edebeb",padding:3,borderRadius:8,height:30}}>Completado</p>                                
                                :
                                  <p style={{marginTop:3,marginLeft:10,fontWeight:"bold",fontSize:14,color:'blue',backgroundColor:"#edebeb",padding:3,borderRadius:8,height:30}}>En progreso</p>                                
                                }
                                


                                {value['devoluciones']!=undefined?
                                value['devoluciones'].includes("Devolucion")?
                                <p style={{marginTop:3,marginLeft:10,fontWeight:"bold",fontSize:14,color:'red',backgroundColor:"#edebeb",padding:3,borderRadius:8,height:30}}>Devolucion</p>
                                :''
                                :''
                                }
                                
                              </AccordionSummary>
                                

                                {
                                <List sx={{bgcolor: 'background.paper', maxHeight:240,position:'relative',overflow: 'auto',marginLeft:4,marginRight:4}}> 
                                  {filteredTranf(value['idtransaccion']).map((valueM) => (
                                  <ListItem>
                                    <ListItemText
                                    primary={valueM.chasis}
                                    secondary={`Marca:${valueM.marca}, Color:${valueM.colore}`}/>
                                      {valueM.status=="Completado"?
                                      <Check style={{fontWeight:"bold",color:"green",marginRight:10}}></Check>
                                       :''}
                                       {valueM.status=="Devuelta"?
                                       <LocalShippingRounded style={{fontWeight:"bold",color:"red",marginRight:10}}></LocalShippingRounded>
                                       :''}
                                       {valueM.status=="En progreso"?
                                        <Close style={{fontWeight:"bold",color:"red",marginRight:10}}></Close>
                                       :''}
                                   </ListItem>
                                   ))}
                                </List>
                                }
                        </Accordion>


                    ))}


                </Grid>
            </Grid>

        </Grid>
        <Grid item lg={4} marginTop={20}>
          {currentItem!=undefined?
          <Box width={400} height={450} borderRadius={3} boxShadow={5} style={{backgroundColor:'white',padding:20}} >
            <Grid container>
              <Grid item lg={12} style={{backgroundColor:"white"}}>
                <h3>Detalles</h3>
              </Grid>          
              <Grid item lg={6}  marginTop={2}>
                <p style={{fontWeight:"bold",fontSize:14}}>status:</p>
              </Grid>  
              <Grid item lg={6}  marginTop={2}>
                {iscompleted(currentItem["idtransaccion"])?
                  <p style={{fontWeight:"bold",fontSize:14,color:'green'}}>Completado</p>
                : <p style={{fontWeight:"bold",fontSize:14,color:'blue'}}>En progreso</p>}
                
              </Grid>

              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Transportador:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.transportista}</p>
              </Grid>  

              <Grid item lg={6} >
                <p style={{fontWeight:"bold",fontSize:14}}>Proviene de:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.stransmisor.split("-")[1]}</p>              
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Enviado por:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.transmuser}</p>
              </Grid>
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Fecha de salida:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.fechatransm}</p>
              </Grid>
              <Grid item lg={6} >
                <p style={{fontWeight:"bold",fontSize:14}}>Recibido en:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.sreceptor.split("-")[1]}</p>              
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Recibido por:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.receptoruser}</p>
              </Grid>
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Fecha de llegada:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.fecharecep}</p>
              </Grid>
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Cantidad:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{filteredTranf(currentItem["idtransaccion"]).length}</p>
              </Grid>
              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Devoluciones:</p>
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{devoluciones(currentItem["idtransaccion"]).length}</p>
              </Grid>

              <Grid item lg={6}>
                <p style={{fontWeight:"bold",fontSize:14}}>Comentario de salida:</p>              
              </Grid>  
              <Grid item lg={6}>
                <p style={{fontSize:14}}>{currentItem.comentransm}</p>              
              </Grid>  

            </Grid>

          </Box>
          :''}


        </Grid>

     </Grid>   
    );    
}
export default Listtransf;
