import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Draw from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIconIzq from '@mui/icons-material/Menu';
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import { ListItemButton, useMediaQuery } from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets'
import Avatar from '@mui/material/Avatar'
import Close from '@mui/icons-material/Close'
import Receipt from '@mui/icons-material/Receipt'
import { useMaterialUIController,setOpenSide } from "context";
import Color from 'assets/theme-dark/base/colors'
import theme from "assets/theme";
import brandWhite from "assets/images/icons/icon-back.png";
import MDBox from "components/MDBox";
import Navbar from "examples/Navbars/NavBarInformMotores";
import MDTypography from "components/MDTypography";
import {
  setMiniSidenav,
} from "context";


var drawerWidth = 350;


function Prestamos({title}) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  const [ishover, setIshover] = useState(false) 
  const [controller, dispatch] = useMaterialUIController();
  const { openSide } = controller;
  const location = useLocation();
  const [openDrawer,setOpendrawer] = useState(true) 
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const colorbarra = Color
  
  const navigate = useNavigate()
    
  const handledesplegarmenu = () => {
    setDesplegarmenu(!desplegarmenu);
  };

  const drawerWidth = 240

  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  //const {roleWeb} = controller
  const roleWeb = localStorage.getItem("roleweb")

  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const sidebarWidth = 250;
  const { transparent, gradients, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem, linearGradient } = functions;
  const darkMode = false;
  let backgroundValue = darkMode
  ? background.sidenav
  : linearGradient(gradients.dark.main, gradients.dark.state);
    
  
  function handleMenu(){
    navigate('/dashboard')
  }

  return(
     <div> 
          <div>
            <CssBaseline />
            <AppBar position='fixed' style={{backgroundColor: colorbarra.background.sidenav,visibility:"hidden"}}  >
                <Toolbar>
                  <IconButton style={{color:'white'}}  onClick={() => setOpendrawer(true)}>
                    <MenuIconIzq
                     
                    />
                  </IconButton> 
                  
                  <Typography variant='h6' style={{flexGrow: '1',color:'white'}}>
                        PRESTAMOS
                  </Typography>
                  
                  <Box display="flex" alignItems = "center" flexDirection="column">
                    <IconButton size='small' style={{paddingLeft: 15}} onClick={handleMenu}>   
                     <WidgetsIcon style={{color:"white"}} />
                    </IconButton>
                    <Typography style={{fontSize: "12px", color:"white"}} >Menu</Typography>
                  </Box>
                  
                  <Box display="flex" alignItems = "center" flexDirection="column">
                    <IconButton size='small' style={{paddingLeft: 15}} onClick={()=>navigate("/login")} >   
                     <LogoutIcon style={{color:"white"}} />
                    </IconButton>
                    <Typography style={{fontSize: "12px", color: "white"}}>Salir</Typography>   
                  </Box>
                  
                  <Box display="flex" alignItems = "center" flexDirection="column" style={{marginLeft:"1%"}}>
                     <IconButton size='small' style={{paddingLeft: 15}} >   
                        <Avatar sx={{width: 25, height: 25}}></Avatar>
                        </IconButton>
                    <Typography style={{fontSize: "12px", color:"white"}}>Usuario</Typography>
                  </Box>
                    
                    
                </Toolbar>
                         
            </AppBar>
            <Navbar title={title}/>      

            <Draw
             
             sx={{
              boxShadow:xxl,
              width: 300,
              '& .MuiDrawer-paper': {
                width: 290,
                boxSizing: 'border-box',
                background: backgroundValue,
                },
              }}
              variant = 'persistent'
              open = {pantxssm?miniSidenav:openDrawer}
             
            >
            <div >     
            
            <Box style={{display:"flex",marginLeft:"0px", marginRight:"0px"}} >
              {pantxssm?
              <IconButton onClick={handleMiniSidenav} style={{width:"50%",justifyContent:"left",color: "white"}}              >
                <Close/>
              </IconButton>
              :""}
            </Box>
            <Divider/>

            <List sx={{
                // selected and (selected + hover) states
                '&& :hover': {color: 'white'},
                '.MuiListItemText-primary':{fontSize: '16px', fontFamily:"TitilliumWebBold"},
               }} style={{marginLeft:"15px"}}>
                
                <ListItem  style={{marginLeft:"0px",marginTop:"20px",width:"95%"}}>
                  <ListItemButton onClick={() => programaacargar(100)} >
                        <ListItemIcon style={{width:"15px",marginLeft:"10px",color: colorbarra.white.main}}>
                            <MDBox component="img" src={brandWhite} alt="Brand" width="2rem" />
                        </ListItemIcon>
                        <MDTypography component="h1" variant="button" fontWeight="medium" fontSize="15px" style={{color:"white",marginLeft:"-1%"}} >Prestamos financieros</MDTypography>
                  </ListItemButton>
                 </ListItem>
                 <Divider
                 style={{background:"black",width:"300",height:"1px",margin:"20px"}}/>


              {roleWeb === 'Administrador' || roleWeb === 'AdmG' || roleWeb === 'Usuario' ?    
                  <ListItem  style={{borderRadius:"5px",marginLeft:"-5px",marginTop:"10px",width:"95%"}} sx={{'&& :hover': {backgroundColor: '#296cd9',borderRadius:"5px"}}}>
                      <ListItemButton onClick={() => programaacargar(0)} >
                        <ListItemIcon style={{width:"15px",color: colorbarra.white.main}}>
                          <Receipt/> 
                        </ListItemIcon>
                        <MDTypography component="h3" variant="caption" fontSize="14px" style={{color:"white",marginLeft:"-20px"}} >Informe de Prestamos</MDTypography>
                      </ListItemButton>
                  </ListItem>
              :''}  
              {roleWeb === 'Administrador' || roleWeb === 'AdmG' ?  
                  <ListItem  style={{borderRadius:"5px",marginLeft:"-5px",marginTop:"10px",width:"95%"}} sx={{'&& :hover': {backgroundColor: '#296cd9',borderRadius:"5px"}}}>
                  <ListItemButton onClick={() => programaacargar(1)} >
                    <ListItemIcon style={{width:"15px",color: colorbarra.white.main}}>
                      <Receipt/> 
                    </ListItemIcon>
                    <MDTypography component="h3" variant="caption" fontSize="14px" style={{color:"white",marginLeft:"-20px"}} >Listar cancelados por fechas</MDTypography>
                  </ListItemButton>  
                </ListItem>
              :''}
              {roleWeb === 'Administrador' || roleWeb === 'AdmG' ?  
              <ListItem  style={{borderRadius:"5px",marginLeft:"-5px",marginTop:"10px",width:"95%"}} sx={{'&& :hover': {backgroundColor: '#296cd9',borderRadius:"5px"}}}>
              <ListItemButton onClick={() => programaacargar(2)} >
                    <ListItemIcon style={{width:"15px",color: colorbarra.white.main}}>
                       <Receipt/> 
                    </ListItemIcon>
                    <MDTypography component="h3" variant="caption" fontSize="14px" style={{color:"white",marginLeft:"-20px"}} >Restaurar Cancelar Prestamos</MDTypography>
                  </ListItemButton>    
                </ListItem>
              :''}
              
            </List>
       
          </div>
      
          </Draw>
          
          
        </div>
    </div>

    );

    function programaacargar(index){
      if(index === 100){
        navigate('/dashboard')
      }

      if(index === 0) {
        navigate('/prestamos/informeprestamos')
      }
      
      if(index === 1) {
        navigate('/prestamos/listarcanceladosporfechas')
      }
      
      if(index === 2) {
        navigate('/prestamos/restaurarcancelarprestamos')
      }
      

    }    
}
export default Prestamos
