import React, { useState,useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Administracion from './administracion';
import theme from "assets/theme";
import Iconpdf from 'assets/pdf/iconpdf';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import { WindowSharp } from '@mui/icons-material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import {TextField} from '@mui/material';

import Sidenav from "examples/Sidenav";

export default function ConsultaMatriculas() {
  var tcosto = 0.00
  var tvalor = 0.00

  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rowsdata,setRowsdata] = useState([])
  const[rowsdata2,setRowsdata2] = useState([])
  const[rowsdata3,setRowsdata3] = useState([])
  const[opendialog,setOpendialog] = useState(false)
  const[mensaje,setMensaje] = useState("")

  
  const opcionstatus = ['T=Todos','I=Incautados','V=Vendido', 'N=Normal']
  const[status,setStatus] = useState(opcionstatus[0])
  const[sucursalnueva,setSucursalnueva] = useState("")
  const[sucursalactual1,setSucursalactual1] = useState("")
  const[chasis,setChasis] = useState("")
  const[listasucursales,setListasucursales] = useState([])
  const { breakpoints,typography } = theme;
  const drawerWidth = 240
  const [detalleMatri,setDetalleMatri] = useState({})
  const [abrirpdf,setAbrirpdf] = useState(false)
  const[sucursal,setSucursal] = useState([])
    
  
  const [presentarpdf,setPresentarpdf] = useState(false)

  const[controller] = useMaterialUIController()
  const {nombreNegocio,roleWeb} = controller 
  


  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/consultarmatricula',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
            //rows = [data["facturacion"]]
            setRowsdata(data["matriculas"])
            setRowsdata2(data["matriculas2"])  
            setRowsdata3(data["pagomatri"])
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
//            alert(data["Error"])
            
          })
      }})
  },[]);

  function handlechasis(value){
    setChasis(value)

    console.log(rowsdata3)
    setDetalleMatri(rowsdata3.find(value => value['chasis']===value))

  }

  function buscar(){
    var item = rowsdata2.filter(value => value['chasis']===chasis);    
    if(item.length!=0){
      setMensaje("Matricula disponible")
    }

}


    return(
     
     <Box component="main" > 
        <Grid container >
              <Grid item xs={12} md={12} lg={12} marginLeft={'20px'}>
                <Typography variant="h4" fontWeight={"bold"} >{"Buscar Chasis"}</Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Autocomplete
                    style={{marginTop:"15px",marginLeft:"20px"}}
                    variant = "standard"    
                    disablePortal
                    id="combo-box-demo"
                    options={rowsdata}
                    value = {chasis}
                    onChange={(event,newValue)=>{handlechasis(newValue);}}
                    onBlur={(event) => handlechasis(event.target.value)}
                    renderInput={(params) => 
                      <TextField {...params}  
                        label="Busqueda" 
                        slotProps={{
                        input: {
                          ...params.InputProps,
                          type: 'search',
                        },
                      }}/>}                    
                  />
              </Grid>
              <Grid item xs={9} md={9} lg={9}>
              {chasis!=""?
              <Box width={450} height={200} borderRadius={3} boxShadow={5} style={{backgroundColor:'white',padding:20, marginTop:20, marginLeft:20}} >
                
                <Grid container>
                  <Grid item lg={12} style={{backgroundColor:"white"}}>
                    <h3>Detalles</h3>
                  </Grid>          

                  <Grid item lg={6} marginTop={2}>
                    <p style={{fontWeight:"bold",fontSize:14}}>Nombre:</p>
                  </Grid>  
                  <Grid item lg={6} marginTop={2}>
                    <p style={{fontSize:14}}>{detalleMatri==undefined?"-":detalleMatri.nombres}</p>
                  </Grid>  

                  <Grid item lg={6} >
                    <p style={{fontWeight:"bold",fontSize:14}}>Apellido:</p>
                  </Grid>  
                  <Grid item lg={6}>
                    <p style={{fontSize:14}}>{detalleMatri==undefined?"-":detalleMatri.apellidos}</p>
                  </Grid>  
                  <Grid item lg={6}>
                    <p style={{fontWeight:"bold",fontSize:14}}>Pagado:</p>
                  </Grid>  
                  <Grid item lg={6}>
                    <p style={{fontSize:14}}>{detalleMatri==undefined?"-":detalleMatri.valor}</p>
                  </Grid>
                </Grid>
              </Box>
              :''}

              {chasis=="" && rowsdata2.length!=0?
                <Box padding={4} style={{backgroundColor:"white", marginTop:10, marginLeft:20}}>
                  <h4>Seleccione matricula</h4>
                </Box>
             :''}

              {rowsdata2.length==0?
                <Box padding={4} style={{backgroundColor:"white", marginTop:10, marginLeft:20}}>
                   <h4>No hay matriculas disponibles</h4>
                </Box>
              
              :''}




              </Grid>
        </Grid>


        
     </Box>

    );    
}

